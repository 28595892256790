import { EBTN_NAME } from '../../components/AdminList/ActionsButtonsInfo/ButtonsData/enums';
import { PdfDocumentStatus } from '../../submodules/common/enums/pdf-document/pdf-document-status.enum';
import { IPdfDocumentDTO } from '../../submodules/common/interfaces/dto/pdf-document/pdf-document.interface';
import { EMessageType, IMessageData } from '../../types/messagesTypes';
import { mimeType } from './Form/utils';

export const optionsTitle = {
  create: 'Upload PDF Document(s)',
  update: 'Update PDF Document',
  info: 'Info PDF Document',
};

export enum ESuccessMessages {
  CREATE = 'PDF Document(s) has been uploaded successfully',
  DELETE = 'PDF Document has been deleted successfully',
  UPDATE = 'PDF Document has been updated successfully',
}

export const handleResponse = (response: IMessageData, callback) => {
  if (response?.messageData?.messageType === EMessageType.SUCCESS) {
    callback();
  }
};

export const defaultFormValues = (
  values: Record<string, unknown>,
  provideDefaultValues?: Record<string, unknown>
): Record<string, unknown> => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (provideDefaultValues) {
      return {
        ...acc,
        [key]:
          value ?? key in provideDefaultValues ? provideDefaultValues[key] : '',
      };
    }
    return { ...acc, [key]: value ?? '' };
  }, {});
};

export const title = 'PDF Documents';

export const defaultSelectedItem: IPdfDocumentDTO & {file: FileList} = {
  reason: '',
  filename: '',
  status: PdfDocumentStatus.Ready,
  id: 0,
  created: '',
  link: '',
  content: '',
  file: {} as FileList,
};

export const stringifyFile = (obj = {}) => {
  const replacer = [] as string[];
  for (const key in obj) {
    replacer.push(key);
  }
  return JSON.parse(JSON.stringify(obj, replacer));
};

export const getExtensionFile = (name: string) => {
  const m = name.match(/\.([^.]+)$/);
  return (m && m[1]) ?? '';
};

export const fileUploadObj = (obj = {}) => {
  const values = Object.values(obj ?? {})?.map((i) => {
    return {...stringifyFile(i ?? {}), isRowError: !mimeType.includes(getExtensionFile(i?.['name'] ?? ''))};
  });
  return {
    length: values.length,
    data: values,
    size: values.reduce((prev, current) => prev + current?.size ?? 0, 0)/1024/1024,
  };
};

export const prepareData = (data: IPdfDocumentDTO[]) => {
  return data.map((el) => ({
    ...el,
    isRowError: el?.status === PdfDocumentStatus.Error,
    isNotClickable: el?.status !== PdfDocumentStatus.Ready,
  }));
};

export const formActionButtonName = {
  [EBTN_NAME.CREATE_NAME_FIELD]: 'Upload',
  [EBTN_NAME.UPDATE_NAME_FIELD]: 'Update',
  [EBTN_NAME.CANCEL_NAME_FIELD]: 'Cancel',
};
