import { Link } from 'react-router-dom';
import { IMenuItem } from './types';

export const MenuSidebarItem = ({ menuItem }: { menuItem: IMenuItem }) => {
  return (
    <li className={location.pathname.startsWith(menuItem.route) ? 'active' : ''
    }>
      <Link
        to={menuItem.route}
        title={menuItem.name}
      >
        <span>{menuItem.icon}</span>
        <span>{menuItem.name}</span>
      </Link>
    </li>
  );
};
