import { IApiResponseDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IErrorDTO } from '../submodules/common/interfaces/dto/common/ierror.interface';
import { IFieldValidationErrorDTO } from '../submodules/common/interfaces/dto/common/ifield-validation-error.interface';
import { IParamErrorDTO } from '../submodules/common/interfaces/dto/common/iparam-error.interface';
import { EMessageType, IMessageType } from '../types/messagesTypes';

export const initPagination = {
  pageSize: 10,
  pageNumber: 1,
};

export const DOTS = '...';
export const DEFAULT_SIBLING_COUNT = 1;

export const convertToQueryString = <T extends object>(params: T): string => {
  /**
   * if you don't wont to see object value in a link params, just name it 'metaData'
   */
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (
      (!value && value !== 0) ||
      (Array.isArray(value) && value.length === 0) ||
      key === 'metaData'
    ) {
      return acc;
    }

    const divider = acc === '' ? '' : '&';

    return acc + `${divider}${key}=${value}`;
  }, '');
};

export const resize = (el: HTMLTextAreaElement) => {
  el.style.height = `${el.scrollHeight}px`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replacer = (key: string, value: any) => {
  if (value !== null || value !== 'undefined') {
    return value;
  }
};

export const toUpperCaseFirsLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const prepareMessageData = (data: IApiResponseDTO, error: IErrorDTO, successMessage?: string): IMessageType => {
  if (error) {
    return {
      messageType: EMessageType.ERROR,
      messages: [data.statusCode.toString(), error?.errorCode ?? 'Operation with error'],
    };
  }
  return {
    messageType: EMessageType.SUCCESS,
    messages: [successMessage ?? ''],
  };
};

// export interface IErrorDTO {
//   errorCode: string;
//   filedsValidationErrors?: IFieldValidationErrorDTO[];
//   paramsErrors?: IParamErrorDTO[];
// }

// export interface IFieldValidationErrorDTO {
//   name: string;
//   errorMessage: string;
// }

// export interface IParamErrorDTO {
//   name: string;
//   value: string;
// }

// enum errorKey {
//   FIELDS_VALIDATION_ERRORS = 'filedsValidationErrors',
//   PARAMS_ERRORS = 'paramsErrors',
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const prepareValidationError = (error: IFieldValidationErrorDTO[]): any[] => {
  return [error.flatMap((el) => [el.errorMessage, el.name]).flat()].flat();
};

const prepareParamsError = (error: IParamErrorDTO[]): string[] => {
  return [error.flatMap((el) => [el.name, el.value]).flat()].flat();
};

export const prepareError = (error: IErrorDTO) => {
  return Object.keys(error).map((key) => {
    const value = error[key];
    if (typeof value === 'string') {
      return value;
    }
    if (error?.filedsValidationErrors) {
      return prepareValidationError(error?.filedsValidationErrors).flat();
    }
    if (error?.paramsErrors) {
      return prepareParamsError(error?.paramsErrors);
    }
  }).flat();
};

export const defaultMessageData = {
  messageData: {
    messageType: '',
    messages: [],
  },
};

export const splitByCapitalLetters = (value: string) => {
  return value.split(/(?=[A-Z])/).join(' ');
};
