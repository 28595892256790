/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { ChangeEvent, useEffect } from 'react';
import classNames from 'classnames';
import TableCheckbox from '../../../components/Table/TableCheckbox';

// ---

export const stringifyFile = (obj = {}) => {
  const replacer = [] as string[];
  for (const key in obj) {
    replacer.push(key);
  }
  return JSON.parse(JSON.stringify(obj, replacer));
};

// ---

export const columns = [
  {
    id: 'expander',
    Header: () => null,
    Cell: ({ row }) => {
      const rowCanExpand = row?.original?.subRows?.length > 0;
      return rowCanExpand ?
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              width: `${(row.depth + 1) * 40}px`,
            },
          })}
          className={classNames('toggle-expand', { expanded: row.isExpanded })}
        /> :
        <span className={classNames('spacer-expanded', { empty: !Boolean(row.depth) })} style={{ width: `${(row.depth + 1) * 40}px` }} />;
    },
    className: 'expanded-column',
    width: 40,
    maxWidth: 40,
  },
  {
    Header: (props) => <CheckboxHeaderCell {...props} title='' withoutText={false} />,
    id: 'select',
    className: 'checkbox-column',
    width: 40,
    maxWidth: 40,
    Cell: (props) => <CheckboxBodyCell {...props} />,
    disableTooltipHeader: true,
    disableTooltip: true,
  },
  {
    Header: 'Parameters',
    accessor: 'parameters',
    disableFilters: true,
    disableSortBy: true,
    className: 'parameters-column',
  },
  {
    Header: 'Details',
    accessor: 'details',
    disableFilters: true,
    disableSortBy: true,
    className: 'details-column',
  },
];

// ---

export const CheckboxBodyCell = (props) => {
  const { row, state, rowFetching, filteredFlatRows, forceUpdate } = props;
  const rowId = row.id;

  const child = Object.keys(state.selectedRowIds)?.filter((i) => i.match(new RegExp('^' + rowId + '.\\d+$')))
    ?.map((i) => state.selectedRowIds[i]);

  const indeterminate = !!child.length ?
    Array.from(new Set(child?.map((i) => !!i))).length !== 1 : false;

  const value = !!child.length ?
    (Array.from(new Set(child?.map((i) => !!i))).length === 1 ? child[0] : indeterminate ? true : false) :
    !!state?.selectedRowIds?.[rowId];

  const handleOnChange = (e) => {
    const value = e?.currentTarget?.checked;
    state.selectedRowIds = {...state.selectedRowIds, [rowId]: indeterminate ? indeterminate : value};

    filteredFlatRows?.forEach((element) => {
      if (element.id.startsWith(`${rowId}.`)) {
        state.selectedRowIds = {...state.selectedRowIds, [element.id]: indeterminate ? indeterminate : value};
      }
    });

    forceUpdate();
  };

  useEffect(() => {
    if (state?.selectedRowIds?.[rowId] !== value) {
      state.selectedRowIds = {...state.selectedRowIds, [rowId]: value};
      forceUpdate();
    }
  }, [state.selectedRowIds]);

  return (
    <TableCheckbox
      onChange={handleOnChange}
      checked={value}
      disabled={!!rowFetching}
      disabledOnClick
      indeterminate={indeterminate}
    />
  );
};

// ---

export const CheckboxHeaderCell = ({ toggleRowSelected, rowFetching, page, filteredFlatRows }) => {
  const isSelectedRows = page.filter((row: { isSelected: boolean; }) => row.isSelected === true).length;
  const indeterminate = isSelectedRows < page.length && isSelectedRows !== 0;
  const isChecked = isSelectedRows === page.length && page.length !== 0;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueChecked = e.currentTarget.checked;

    filteredFlatRows.forEach((el: { id: string; isSelected: boolean; }) => {
      toggleRowSelected(el.id, valueChecked);
      el.isSelected = valueChecked;
    });
  };

  return (
    <TableCheckbox
      onChange={handleOnChange}
      checked={isChecked}
      disabled={!!rowFetching || page.length === 0}
      disabledOnClick
      data-qa='table-checkbox-body'
      indeterminate={indeterminate}
    />
  );
};

// ---

export const nest = (items, flatId = '', parentId = 'parentId') => {
  return items
    ?.filter((item) => item[parentId] === flatId)
    ?.map((item) => {
      const value = {...(item ?? {})};
      delete value.flatId;
      delete value.depth;
      delete value.parentId;
      return !!nest(items, item.flatId)?.length ? { ...value, subRows: nest(items, item.flatId) } : { ...value };
    });
};

// ---

export enum ESuccessMessagesSettings {
  UPDATE = 'Settings imported successfully',
}
