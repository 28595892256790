/* eslint-disable max-len */
import { ESuccessMessagesUpdate } from '../../../pages/DocMdContent/utils';
import { PdfDocumentRoutes } from '../../../submodules/common/enums/routes/pdf-document-routes.enum';
import { IUpdatePdfDocumentDTO } from '../../../submodules/common/interfaces/dto/pdf-document/iupdate-pdf-document.interface';
import { IPdfDocumentDTO } from '../../../submodules/common/interfaces/dto/pdf-document/pdf-document.interface';
import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { EMethod } from '../utils';
import { adminApi } from './adminApi';


export const pdfDocContentApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({

    getPdfDocById: builder.query<{ data: IPdfDocumentDTO }, { id: number | string }>({
      query: ({ id }) => ({
        url: `${PdfDocumentRoutes.BasePrefix}/${id}`,
        method: EMethod.GET,
      }),
    }),

    updatePdfDocumentContent: builder.mutation<IMessageData, { id: number | string, body: IUpdatePdfDocumentDTO }>({
      query: ({ id, body }) => ({
        url: `${PdfDocumentRoutes.BasePrefix}/${id}`,
        method: EMethod.PUT,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessagesUpdate.UPDATE] } };
      },
    }),

  }),
});

export const {
  useGetPdfDocByIdQuery,
  useUpdatePdfDocumentContentMutation,
  internalActions: { resetApiState: resetPdfDocContentApi },
} = pdfDocContentApi;
