/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import classnames from 'classnames';
import { useAuth } from '../../hooks/useTypeSelectorHooks/useAuth';
import { REDIRECT_AFTER_LOGIN } from '../../routes/adminRoutes/util';

const Header = ({expanded, setExpanded}: {expanded: boolean, setExpanded: Dispatch<SetStateAction<boolean>>}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { account } = useAuth();

  const firstName = account?.firstName ?? '';
  const lastName = account?.lastName ?? '';

  const menuRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDocumentClick = (event: any) => {
    if (
      buttonRef.current && !buttonRef.current.contains(event.target) &&
      menuRef.current && !menuRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  // ---

  const headerRef = useRef<HTMLElement>(null);
  const onScroll = () => {
    if (!!headerRef?.current) {
      headerRef.current.style.transform = `translateX(-${scrollX}px)`;
    }
  };

  // ---

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll);
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  // ---

  return (
    <header ref={headerRef} className='header'>
      <div className="container">
        <div>
          <button
            onClick={() => setExpanded((value) => !value)}
            className={classnames('burger-btn', {expanded})}
          >
            <div className='menu_checkbox'>
              <span/>
              <span/>
              <span/>
            </div>
          </button>
        </div>
        <div>
          <Link to={REDIRECT_AFTER_LOGIN} className='header-logo'>
            <span />
          </Link>
        </div>
        <div>
          <ul className='header-profile'>
            <li className={classnames({ active: isMenuOpen })}>
              <button className="header-avatar" onClick={toggleMenu} ref={buttonRef}>
                <span>
                  {firstName && firstName[0].toUpperCase()}{lastName && lastName[0].toUpperCase()}
                </span>
              </button>
            </li>
          </ul>
          <Menu ref={menuRef} isOpen={isMenuOpen} />
        </div>
      </div>
    </header>
  );
};

export default Header;
