import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import MenuSidebar from '../components/MenuSidebar';
import classnames from 'classnames';
import { useState } from 'react';

const Layout = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <Header expanded={expanded} setExpanded={setExpanded}/>
      <div className={classnames('wrapper-right-menu', {expanded})}>
        <div>
          <MenuSidebar />
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
