/* eslint-disable react/prop-types */
import { SyntheticEvent } from 'react';

export const columnsMemoInfo = [
  {
    Header: 'Parameter',
    accessor: 'parameter',
    disableSortBy: true,
    disableFilters: true,
    width: 150,
    maxWidth: 150,
    className: 'parameter',
  },
  {
    Header: 'Value',
    accessor: 'value',
    disableSortBy: true,
    disableFilters: true,
  },
];

export const columnsMemoFile = (deleteSelectFile, clearAll) => [
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Size (MB)',
    accessor: 'size',
    disableSortBy: true,
    disableFilters: true,
    className: 'size',
    width: 150,
    maxWidth: 150,
    Cell: ({ value }: { value: number }) => {
      return <>{(value/1024/1024).toFixed(2)}</>;
    },
  },
  {
    Header: ({isDisabledAllFields}) => {
      return <div className="actions-btn-list">
        <button
          disabled={isDisabledAllFields}
          className="btn close"
          onClick={(e: SyntheticEvent<Element, Event> | undefined) => {
            e?.preventDefault();
            e?.stopPropagation();
            clearAll();
          }}
        >
          <span/>
        </button>
      </div>;
    },
    id: 'actions',
    className: 'admin-actions',
    disableFilters: true,
    disableSortBy: true,
    disableTooltip: true,
    width: 64,
    maxWidth: 64,
    Cell: ({ row: { id }, isDisabledAllFields}) => {
      return <div className="actions-btn-list">
        <button
          disabled={isDisabledAllFields}
          className="btn close"
          onClick={(e: SyntheticEvent<Element, Event> | undefined) => {
            e?.preventDefault();
            e?.stopPropagation();
            deleteSelectFile(id);
          }}
        >
          <span/>
        </button>
      </div>;
    },
  },
];

export const mimeType = [
  'pdf',
  'docx',
  'md',
];
