// import { ReactComponent as BurgerOpen } from '../../assets/svg/menu-expanded.svg';
// import { ReactComponent as BurgerClose } from '../../assets/svg/menu.svg';
import { MenuSidebarList } from './MenuSidebarList';
import { menuConfig } from './config';

const MenuSidebar = () => {
  return (
    <div className='menu-sidebar'>
      <div>
        <MenuSidebarList menu={menuConfig} />
      </div>
    </div>
  );
};

export default MenuSidebar;
