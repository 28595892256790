import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from './App';
import { ConfirmationServiceProvider } from './components/ConfirmationDialog/ConfirmationService';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

export const broadcastChannel = new BroadcastChannel('chatAdminChanel');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <ConfirmationServiceProvider>
        <App />
      </ConfirmationServiceProvider>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
);
