/* eslint-disable indent */
import { createContext, useContext, useState, useRef } from 'react';
import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog';

interface IRef {
  resolve: () => void;
  reject: () => void;
}

const ConfirmationServiceContext = createContext<{
  confirmModal:(options: ConfirmationOptions) => Promise<void>,
  setConfirmationState: React.Dispatch<React.SetStateAction<ConfirmationOptions | null>>,
}>({
  confirmModal: Promise.reject,
  setConfirmationState: () => ({}),
});

export const useConfirmation = () =>
  useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }: { children }) => {
  const [
    confirmationState,
    setConfirmationState,
  ] = useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = useRef<IRef>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={{
          confirmModal: openConfirmation,
          setConfirmationState: setConfirmationState,
        }}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />

      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
