/* eslint-disable max-len */
import { ESuccessMessages, prepareData } from '../../../pages/PDFDocuments/utils';
import { PdfDocumentRoutes } from '../../../submodules/common/enums/routes/pdf-document-routes.enum';
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IPaginatedResultDTO } from '../../../submodules/common/interfaces/dto/common/ipaginated-result.interface';
import { IGetPdfDocumentsRequestDTO } from '../../../submodules/common/interfaces/dto/pdf-document/iget-pdf-documents-request.interface';
import { IUpdatePdfDocumentDTO } from '../../../submodules/common/interfaces/dto/pdf-document/iupdate-pdf-document.interface';
import { IPdfDocumentDTO } from '../../../submodules/common/interfaces/dto/pdf-document/pdf-document.interface';
import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { EMethod } from '../utils';
import { adminApi } from './adminApi';
import { tagTypesPdfDocuments } from './utils';


export const pdfDocumentsApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({

    getListPdfDocuments: builder.query<{ data: IPdfDocumentDTO[], totalCount: number, initialFetching: boolean }, { body: IGetPdfDocumentsRequestDTO | null }>({
      query: ({ body }) => ({
        url: `${PdfDocumentRoutes.BasePrefix}/${PdfDocumentRoutes.Search}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPaginatedResultDTO<IPdfDocumentDTO>> & { initialFetching: boolean }) => {
        return {
          data: prepareData(response.data.items ?? []),
          totalCount: response.data.totalCount,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesPdfDocuments,
    }),

    deletePdfDocument: builder.mutation<IMessageData, number>({
      query: (id) => ({
        url: `${PdfDocumentRoutes.BasePrefix}/${id}`,
        method: EMethod.DELETE,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.DELETE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesPdfDocuments;
      },
    }),

    updatePdfDocument: builder.mutation<IMessageData, { id: number, body: IUpdatePdfDocumentDTO }>({
      query: ({ id, body }) => ({
        url: `${PdfDocumentRoutes.BasePrefix}/name/${id}`,
        method: EMethod.PUT,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.UPDATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesPdfDocuments;
      },
    }),

    uploadPdfDocument: builder.mutation<IMessageData, FormData>({
      query: (body) => ({
        url: `${PdfDocumentRoutes.BasePrefix}`,
        method: EMethod.POST,
        body: body,
        // Content-Type should be omitted from headers because it's added automatically
        // with the correct boundary if formData is true
        headers: {},
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.CREATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesPdfDocuments;
      },
    }),

  }),
});

export const {
  useGetListPdfDocumentsQuery,
  internalActions: { resetApiState: resetPdfDocumentsApi },
  useDeletePdfDocumentMutation,
  useUpdatePdfDocumentMutation,
  useUploadPdfDocumentMutation,
} = pdfDocumentsApi;
