import React from 'react';
import Button from '../Button';
import { useActions } from '../../hooks/useActions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Menu = React.forwardRef((props: any, ref: any) => {
  const { isOpen } = props;
  const { logOut } = useActions();

  Menu.displayName = 'WrapperMenu';
  return (
    <>
      {isOpen && (
        <ul
          ref={ref}
          className='header-menu'>
          <li>
            <Button className='link' onClick={() => logOut()}
              isLoading={false} disabled={false}>Log Out</Button>
          </li>
        </ul>
      )}
    </>
  );
});

export default Menu;
