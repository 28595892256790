/* eslint-disable max-len */
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';

import { PromptTemplateRoutes } from '../../../submodules/common/enums/routes/prompt-template-routes.enum';
import { IPromptTemplateInfoDTO } from '../../../submodules/common/interfaces/dto/prompt-templates/iprompt-template-info.interface';
import { IGetPromptTemplatesRequestDTO } from '../../../submodules/common/interfaces/dto/prompt-templates/igetprompt-templates-request.interface';
import { IPromptTemplateDTO } from '../../../submodules/common/interfaces/dto/prompt-templates/iprompt-template.interface';
import { IUpdatePromptTemplateDTO } from '../../../submodules/common/interfaces/dto/prompt-templates/iupdate-prompt-template.interface';
import { EMethod } from '../utils';
import { adminApi } from './adminApi';
import { tagTypesPrompts } from './utils';
import { ICreatePromptTemplateDTO } from '../../../submodules/common/interfaces/dto/prompt-templates/icreate-prompt-template.interface';
import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { ESuccessMessages } from '../../../pages/Category/utils';

export const promptApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrompt: builder.query<{ data: IPromptTemplateInfoDTO[], initialFetching: boolean }, { body: IGetPromptTemplatesRequestDTO | null }>({
      query: ({ body }) => ({
        url: `${PromptTemplateRoutes.BasePrefix}/${PromptTemplateRoutes.Search}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPromptTemplateInfoDTO[]> & { initialFetching: boolean }) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesPrompts,
    }),

    getPromptById: builder.query<{ data: IPromptTemplateDTO, initialFetching: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `${PromptTemplateRoutes.BasePrefix}/${id}`,
        method: EMethod.GET,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPromptTemplateDTO> & { initialFetching: boolean }) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesPrompts,
    }),

    updatePrompt: builder.mutation<{ data: IPromptTemplateDTO, initialFetching: boolean } & IMessageData, { body: IUpdatePromptTemplateDTO }>({
      query: ({ body }) => ({
        url: `${PromptTemplateRoutes.BasePrefix}`,
        method: EMethod.PUT,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPromptTemplateDTO> & { initialFetching: boolean } & IMessageData) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
          messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.UPDATE] },
        };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesPrompts;
      },
    }),

    createPrompt: builder.mutation<{ data: IPromptTemplateDTO, initialFetching: boolean } & IMessageData, { body: ICreatePromptTemplateDTO }>({
      query: ({ body }) => ({
        url: `${PromptTemplateRoutes.BasePrefix}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPromptTemplateDTO> & { initialFetching: boolean } & IMessageData) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
          messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.CREATE] },
        };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesPrompts;
      },
    }),

    deletePrompt: builder.mutation<IMessageData, string>({
      query: (id) => ({
        url: `${PromptTemplateRoutes.BasePrefix}/${id}`,
        method: EMethod.DELETE,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.DELETE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesPrompts;
      },
    }),
  }),
});

export const {
  useGetPromptQuery,
  useGetPromptByIdQuery,
  useUpdatePromptMutation,
  useCreatePromptMutation,
  useDeletePromptMutation,
  endpoints,
  internalActions: { resetApiState: resetPromptApi },
} = promptApi;
