/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import classNames from 'classnames';
import LdsRing from '../LdsRing';

interface IButton extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: ReactNode;
  onClick?: (e?: React.SyntheticEvent) => void;
  className?: string;
  referenceRef?: any;
  isLoading?: boolean;
}

const Button = ({ onClick, children, className, referenceRef, isLoading = false, ...props }: IButton): JSX.Element => {
  const disabled = (props?.disabled ?? false) || isLoading;
  return (
    <button
      ref={referenceRef}
      className={classNames('btn', isLoading ? '' : className, { isLoading: isLoading })}
      onClick={onClick}
      {...props}
      title=''
      disabled={disabled}
    >
      <span>
        {isLoading && <LdsRing />}
        {children}
      </span>
    </button>
  );
};

export default Button;
