/* eslint-disable @typescript-eslint/no-explicit-any */
import classnames from 'classnames';
import React, { forwardRef, Ref, useLayoutEffect, useRef } from 'react';

interface ITableCheckbox {
  indeterminate?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (event: any) => void;
  disabledOnClick?: boolean;
  checkboxTitle?: string;
  withoutText?: boolean;
}

const TableCheckbox = forwardRef<HTMLInputElement, ITableCheckbox>(
  ({ indeterminate, disabledOnClick, checkboxTitle, checked, withoutText = true, ...rest },
    ref: Ref<HTMLInputElement>) => {
    const defaultRef = useRef<any>();
    const resolvedRef: any = ref || defaultRef;
    function handleCheckboxClick(event: React.FormEvent<HTMLInputElement>) {
      if (!disabledOnClick) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate;
    }

    useLayoutEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate, checked]);

    return (
      <div className={classnames('wrapper-checkbox', { 'without-text': withoutText })}>
        <label>
          <input type="checkbox" checked={checked} ref={resolvedRef} {...rest} onClick={(event) => handleCheckboxClick(event)} />
          <span>
            {checkboxTitle}
          </span>
        </label>
      </div>
    );
  }
);

TableCheckbox.displayName = 'TableCheckbox';

export default TableCheckbox;
