import classNames from 'classnames';
import './lds_ring.scss';

interface ILdsRing {
  text?: string;
  className?: string;
}

const LdsRing = ({ text, className }: ILdsRing) => {
  return (
    <div className={classNames('loader-wrapper', className)} >
      <div className="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <>
        {text && <span>{text}&#8230;</span>}
      </>
    </div>
  );
};

export default LdsRing;
