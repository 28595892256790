// import { SingleValue } from 'react-select';
// import { FormType } from '../../submodules/common/enums/common/form.enum';
import { LlmModel } from '../../submodules/common/enums/common/llm-model.enum';
// import { WebDocumentSourceSection } from '../../submodules/common/enums/web-document/web-document-source-section.enum';
import { IPromptTemplateDTO } from '../../submodules/common/interfaces/dto/prompt-templates/iprompt-template.interface';
import { IUpdatePromptTemplateDTO } from '../../submodules/common/interfaces/dto/prompt-templates/iupdate-prompt-template.interface';
import { IFormData } from './Form/types';
// import { formTypeOptions } from './Form/utils';
// import { ITypedSelect } from '../../types/selectTypes';

export const optionsTitle = {
  create: 'Create Prompt',
  update: 'Edit Prompt',
  info: 'Info Prompt',
};

export const defaultSelectedItem: Omit<IPromptTemplateDTO, 'updated'> = {
  created: '',
  documentRetreiverLimit: 0,
  // documentSections: null,
  // formType: null,
  id: '',
  llmModel: LlmModel.GPT_3,
  llmTemperature: 0.5,
  name: '',
  prompt: '',
  tokens: 256,
};

// export const displayDataSources = (
//   data: WebDocumentSourceSection[]
// ): string => {
//   return data.join(', ');
// };

export enum ESuccessMessages {
  CREATE = 'Prompt has been created successfully',
  DELETE = 'Prompt has been deleted successfully',
  UPDATE = 'Prompt has been updated successfully',
}

export const prepareFormDataToSent = (
  formData: IFormData
): IUpdatePromptTemplateDTO => {
  const { llmModel,
    // documentSections,
    // formType,
    id,
    questionCategoryId } =
    formData;

  return {
    ...formData,
    llmModel: llmModel?.value || '',
    // documentSections: documentSections.map(
    //   (element) => element?.value as WebDocumentSourceSection
    // ),
    // formType: formType?.value || null,
    id: id || '',
    questionCategoryId: questionCategoryId || null,
  };
};

// const documentsSectionMapper = (data: WebDocumentSourceSection[] | null) => {
//   if (!data?.length) {
//     return [];
//   }

//   return data.map((element) => ({ label: element, value: element }));
// };

// const getOption = <T>(
//   optionValue: string | null | undefined,
//   options: SingleValue<ITypedSelect<T>>[]
// ): SingleValue<ITypedSelect<T>> => {
//   const option = options.find((option) => {
//     return option?.value === optionValue;
//   });

//   return option || null;
// };

export const preparePromptData = (data: IPromptTemplateDTO): IFormData => {
  const {
    llmModel,
    // documentSections,
    // formType,
  } = data;

  return {
    ...data,
    llmModel: { label: llmModel, value: llmModel },
    // documentSections: documentsSectionMapper(documentSections),
    // formType: getOption<FormType>(formType, formTypeOptions),
  };
};

export const defaultMessageData = { messageType: '', messages: [] };
