import { ReactComponent as QuestionAndAnswerIcon } from '../../assets/svg/menu/question_and_answers.svg';
import { ReactComponent as DocumentsIcon } from '../../assets/svg/menu/document.svg';
// import { ReactComponent as QuestionCategoriesIcon } from '../../assets/svg/menu/question_categories.svg';
import { ReactComponent as ChatSessionIcon } from '../../assets/svg/menu/chat_sessions.svg';
import { ReactComponent as ChatIcon } from '../../assets/svg/menu/chat.svg';
import { ReactComponent as SettingsIcon } from '../../assets/svg/menu/settings.svg';
import { ReactComponent as ImportExportIcon } from '../../assets/svg/menu/import_export.svg';

export const menuConfig = [
  {
    route: '/documents',
    icon: <DocumentsIcon />,
    name: 'PDF Documents',
    disabled: false,
  },
  {
    route: '/qa',
    icon: <QuestionAndAnswerIcon />,
    name: 'Question & Answer',
    disabled: false,
  },
  // {
  //   route: '/question-categories',
  //   icon: <QuestionCategoriesIcon />,
  //   name: 'Question Categories',
  //   disabled: false,
  // },
  {
    route: '/sessions-chat',
    icon: <ChatSessionIcon />,
    name: 'Sessions',
    disabled: false,
  },
  {
    route: '/chat-admin',
    icon: <ChatIcon />,
    name: 'Chat',
    disabled: false,
  },
  {
    route: '/settings',
    icon: <SettingsIcon />,
    name: 'Settings',
    disabled: false,
  },
  {
    route: '/export-import-settings',
    icon: <ImportExportIcon />,
    name: 'Import & Export',
    disabled: false,
  },
];
