import { ReactElement } from 'react';
import { PdfDocumentStatus } from '../../submodules/common/enums/pdf-document/pdf-document-status.enum';

export type TSettings = {
  id: number;
  created: string;
  filename: string;
  status: PdfDocumentStatus;
  content: string;
  reason: string;
  link: string;
};

export type TMessageValues = {
  messageType: string,
  messages: Array<string | ReactElement>,
  isMarkHeader?: boolean,
  defaultErrorText?: string
  params?: { [k: string]: number }
};

export enum ESuccessMessagesUpdate {
  UPDATE = 'PDF Document has been updated successfully',
}

export const defaultMessageData = { messageType: '', messages: [] };
