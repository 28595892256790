import { EToken } from '../types/localStorageTypes';
import { getStorage, removeStorage, setStorage, storageDataFields } from '../utils/localStorageUtil';
import { isObject } from '../utils/systemUtils';

export const setLocaleStorageService = (initialValue: { [key: string]: string }) => {
  try {
    const tokens = getStorage(EToken.TOKENS) ?? {};
    if (isObject(tokens)) {
      setStorage(EToken.TOKENS, { ...tokens as object, ...initialValue });
    }
  } catch {
    removeStorage(EToken.TOKENS);
  }
};

export const removeLocaleStorageService = (data: string[]) => {
  try {
    const tokens = getStorage(EToken.TOKENS) ?? {};
    Object.keys(tokens).forEach((key) => {
      if (!storageDataFields.includes(key) || data.includes(key)) {
        delete tokens?.[key];
      }
    });
    if (Object.keys(tokens).length) {
      setStorage(EToken.TOKENS, { ...tokens as object });
    } else {
      removeStorage(EToken.TOKENS);
    }
  } catch {
    removeStorage(EToken.TOKENS);
  }
};

export const getLocalStorageService = (key: string): { [key: string]: string } | string | null => {
  try {
    return getStorage(key);
  } catch {
    removeStorage(EToken.TOKENS);
  }
  return null;
};
