import { resetSessionChatApi } from './api/adminApi/sessionChatApi';
import { resetPdfDocumentsApi } from './api/adminApi/pdfDocuments';
import { resetQuestionAnswerApi } from './api/adminApi/questionAnswerApi';
import { resetPdfDocContentApi } from './api/adminApi/pdfDocContent';
import { resetQuestionCategoriesApi } from './api/adminApi/questionCategoriesApi';
import { resetPromptApi } from './api/adminApi/promptApi';
import { resetSettingsApi } from './api/adminApi/settingsApi';
import {
  setInitialFetching as setInitialFetchingChat, resetChatStore,
  setMessagesHistory, setIsChatAllowed,
} from './reducers/chatAdminSlice';
import { setUser, logOut, setInitialFetching, setIsLogin, setSessionIdUser } from './reducers/authSlice';

export const rootActions = {
  setUser, logOut, setInitialFetching, setIsLogin, setSessionIdUser,
  resetSessionChatApi,
  resetPdfDocumentsApi,
  resetQuestionAnswerApi,
  resetPdfDocContentApi,
  resetQuestionCategoriesApi,
  resetPromptApi,
  resetSettingsApi,

  setInitialFetchingChat, resetChatStore, setMessagesHistory, setIsChatAllowed,
};
