/* eslint-disable max-len */
import { QuestionCategoryRoutes } from '../../../submodules/common/enums/routes/question-category-routes.enum';
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IPaginatedResultDTO } from '../../../submodules/common/interfaces/dto/common/ipaginated-result.interface';
import { IGetQuestionsCategorysRequestDTO } from '../../../submodules/common/interfaces/dto/question-category/iget-questions-categorys-request.interface';
import { IQuestionCategoryDTO } from '../../../submodules/common/interfaces/dto/question-category/iquestion-category.interface';
import { IUpdateQuestionCategoryDTO } from '../../../submodules/common/interfaces/dto/question-category/iupdate-question-category.interface';

import { ESuccessMessages } from '../../../pages/QuestionCategories/utils';

import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { EMethod } from '../utils';
import { adminApi } from './adminApi';
import { tagTypesQuestionCategories } from './utils';
import { ICreateQuestionCategoryDTO } from '../../../submodules/common/interfaces/dto/question-category/icreate-question-category.interface';

export const questionCategoriesApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionCategories: builder.query<{ data: IQuestionCategoryDTO[], totalCount: number, initialFetching: boolean }, { body: IGetQuestionsCategorysRequestDTO | null }>({
      query: ({ body }) => ({
        url: `${QuestionCategoryRoutes.BasePrefix}/${QuestionCategoryRoutes.Search}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPaginatedResultDTO<IQuestionCategoryDTO>> & { initialFetching: boolean }) => {
        return {
          data: response.data.items,
          totalCount: response.data.totalCount,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesQuestionCategories,
    }),

    getQuestionCategoryById: builder.query<{ data: IQuestionCategoryDTO, initialFetching: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `${QuestionCategoryRoutes.BasePrefix}/${id}`,
        method: EMethod.GET,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IQuestionCategoryDTO> & { initialFetching: boolean }) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
        };
      },
    }),

    createCategory: builder.mutation<IMessageData, ICreateQuestionCategoryDTO>({
      query: (body) => ({
        url: `${QuestionCategoryRoutes.BasePrefix}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.CREATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesQuestionCategories;
      },
    }),

    editCategory: builder.mutation<IMessageData, { id: string, body: IUpdateQuestionCategoryDTO }>({
      query: ({ id, body }) => ({
        url: `${QuestionCategoryRoutes.BasePrefix}/${id}`,
        method: EMethod.PUT,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.UPDATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesQuestionCategories;
      },
    }),

    deleteCategory: builder.mutation<IMessageData, number>({
      query: (id) => ({
        url: `${QuestionCategoryRoutes.BasePrefix}/${id}`,
        method: EMethod.DELETE,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.DELETE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesQuestionCategories;
      },
    }),
  }),
});

export const {
  useGetQuestionCategoriesQuery,
  useGetQuestionCategoryByIdQuery,
  internalActions: { resetApiState: resetQuestionCategoriesApi },
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
} = questionCategoriesApi;
