
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultError } from '../../types/messagesTypes';
import { IInitialChatAdminState } from '../../types/reducerTypes/chatAdminNewTypes';
import { IMessageData as IMessageDataError } from '../../types/messagesTypes';
import { IMessageData } from '../../components/Chat/types';

const initialState: IInitialChatAdminState = {
  initialFetching: false,

  isChatAllowed: false,

  messagesHistory: [],

  isFetching: false,
  error: defaultError,
};

const chatAdminSlice = createSlice({
  name: 'chatAdmin',
  initialState,
  reducers: {
    setInitialFetching: (state, action: PayloadAction<boolean>) => {
      state.initialFetching = action.payload;
    },
    setErrorChat: (state, action: PayloadAction<IMessageDataError>) => {
      state.error = action.payload;
    },
    setMessagesHistory: (state, action: PayloadAction<[string | number, IMessageData][]>) => {
      state.messagesHistory = action.payload;
    },
    setIsChatAllowed: (state, action: PayloadAction<boolean>) => {
      state.isChatAllowed = action.payload;
    },
    resetChatStore: () => {
      return initialState;
    },
  },
});

export const {
  setInitialFetching,
  resetChatStore,
  setMessagesHistory,
  setIsChatAllowed,
  setErrorChat,
} = chatAdminSlice.actions;
export const chatAdminReducer = chatAdminSlice.reducer;

