import { Navigate, useLocation } from 'react-router';
import { useAuth } from '../../hooks/useTypeSelectorHooks/useAuth';
import { PrivateRouteProps } from '../../types/privateRouteTypes';

export const PrivateRoute = ({ children, to }: PrivateRouteProps) => {
  const { isLogin } = useAuth();
  const location = useLocation();

  if (isLogin) {
    return children;
  }
  return <Navigate to={to} state={{ from: location }} />;
};
