import { ELocalStorage, EToken } from '../types/localStorageTypes';

export const storageDataFields: string[] = [
  ELocalStorage.AccessToken, ELocalStorage.RefreshToken,
  ELocalStorage.AdminAccessToken, ELocalStorage.AdminRefreshToken];

export const setStorage = (
  key: string, initialValue: { [key: string]: string } | string): void => {
  window.localStorage.setItem(key, JSON.stringify(initialValue));
};

export const getStorage = (key: string): { [key: string]: string } | string | null => {
  const storageDataByKey = window.localStorage.getItem(key);
  if (!storageDataByKey) {
    return null;
  }
  let result = '';
  try {
    result = JSON.parse(storageDataByKey);
  } catch (e) {
    throw e;
  }
  return result;
};

export const removeStorage = (key: string): void => {
  window.localStorage.removeItem(key);
};

export const getTokenByType = (tokenType: ELocalStorage): string => {
  let tokens = '';
  try {
    tokens = getStorage(EToken.TOKENS)?.[tokenType] ?? '';
  } catch {
    removeStorage(EToken.TOKENS);
  }
  return tokens;
};
