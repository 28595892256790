import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IInitialState } from '../../types/reducerTypes/authTypes';
import { IAccountDTO } from '../../submodules/common/interfaces/dto/account/iaccount.interface';
import { authApi } from '../api/adminApi/authApi';
import { IAdminLoginResponseDTO } from '../../submodules/common/interfaces/dto/auth/iadmin-login-response.interfaces';
import { IApiResponseGenericDTO } from '../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { defaultError } from '../../types/messagesTypes';
import { ELocalStorage } from '../../types/localStorageTypes';
import { removeLocaleStorageService, setLocaleStorageService } from '../../services/localStorageService';

const initialState: IInitialState = {
  isLogin: false,
  account: null,

  sessionId: null,
  greeting: null,

  isFetching: false,
  initialFetching: false,
  error: defaultError,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInitialFetching: (state, action: PayloadAction<boolean>) => {
      state.initialFetching = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setSessionIdUser: (state, action: PayloadAction<string | null>) => {
      state.sessionId = action.payload;
    },
    setGreetingIdUser: (state, action: PayloadAction<string | null>) => {
      state.greeting = action.payload;
    },
    setUser: (state, action: PayloadAction<IAccountDTO | null>) => {
      state.account = action.payload;
    },
    logOut: (state) => {
      state.account = null;
      state.isLogin = false;
      state.sessionId = null;
      removeLocaleStorageService([ELocalStorage.AdminAccessToken, ELocalStorage.AdminRefreshToken]);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchPending,
        (state) => {
          state.isFetching = true;
          state.account = null;
          state.isLogin = false;
          state.sessionId = null;
          state.greeting = null;
        },
      ).addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, action: PayloadAction<IApiResponseGenericDTO<IAdminLoginResponseDTO>>) => {
          const { access, account, sessionId, greeting } = action.payload.data;
          state.isFetching = false;
          state.account = account;
          state.isLogin = true;
          state.sessionId = sessionId;
          state.greeting = greeting;
          setLocaleStorageService(
            {
              [ELocalStorage.AdminAccessToken]: access.accessToken,
              [ELocalStorage.AdminRefreshToken]: access.refreshToken,
            },
          );
        },
      ).addMatcher(
        authApi.endpoints.recoveryUser.matchFulfilled,
        (state, action: PayloadAction<IApiResponseGenericDTO<IAdminLoginResponseDTO>>) => {
          const { account, sessionId, greeting } = action.payload.data;
          state.isFetching = false;
          state.account = account;
          state.isLogin = true;
          state.sessionId = sessionId;
          state.greeting = greeting;
        },
      ).addMatcher(
        authApi.endpoints.recoveryUser.matchRejected,
        (state) => {
          state.isFetching = true;
          state.account = null;
          state.isLogin = false;
          state.sessionId = null;
          state.greeting = null;
        },
      );
  },
});

export const { logOut, setInitialFetching, setIsLogin,
  setSessionIdUser, setUser, setGreetingIdUser } = authSlice.actions;
export const authReducer = authSlice.reducer;

