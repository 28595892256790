/* eslint-disable max-len */
import { QuestionAnswerRoutes } from '../../../submodules/common/enums/routes/question-answer-routes.enum';
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IPaginatedResultDTO } from '../../../submodules/common/interfaces/dto/common/ipaginated-result.interface';
import { ICreateQuestionAnswerDTO } from '../../../submodules/common/interfaces/dto/question-answer/icreate-question-answer.interface';
import { IGetQuestionsAnswersRequestDTO } from '../../../submodules/common/interfaces/dto/question-answer/iget-questions-answers-request.interface';
import { IQuestionAnswerDTO } from '../../../submodules/common/interfaces/dto/question-answer/iquestion-answer.interface';
import { IUpdateQuestionAnswerDTO } from '../../../submodules/common/interfaces/dto/question-answer/iupdate-question-answer.interface';

import { EMethod } from '../utils';
import { adminApi } from './adminApi';
import { tagTypesQuestionAnswer } from './utils';
import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { ESuccessMessages } from '../../../pages/QuestionAnswer/utils';


export const questionAnswerApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionAnswer: builder.query<{ data: IQuestionAnswerDTO[], totalCount: number, initialFetching: boolean }, { body: IGetQuestionsAnswersRequestDTO | null }>({
      query: ({ body }) => ({
        url: `${QuestionAnswerRoutes.BasePrefix}/${QuestionAnswerRoutes.Search}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPaginatedResultDTO<IQuestionAnswerDTO>> & { initialFetching: boolean }) => {
        return {
          data: response.data.items,
          totalCount: response.data.totalCount,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesQuestionAnswer,
    }),
    createQuestionAnswer: builder.mutation<IMessageData, ICreateQuestionAnswerDTO>({
      query: (body) => ({
        url: QuestionAnswerRoutes.BasePrefix,
        method: EMethod.POST,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.CREATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesQuestionAnswer;
      },
    }),
    editQuestionAnswer: builder.mutation<IMessageData, { id: number, body: IUpdateQuestionAnswerDTO }>({
      query: ({ id, body }) => ({
        url: `${QuestionAnswerRoutes.BasePrefix}/${id}`,
        method: EMethod.PUT,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.UPDATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesQuestionAnswer;
      },
    }),
    deleteQuestionAnswer: builder.mutation<IMessageData, { id: number }>({
      query: ({ id }) => ({
        url: `${QuestionAnswerRoutes.BasePrefix}/${id}`,
        method: EMethod.DELETE,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.DELETE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesQuestionAnswer;
      },
    }),
  }),
});

export const {
  useGetQuestionAnswerQuery,
  internalActions: { resetApiState: resetQuestionAnswerApi },
  useCreateQuestionAnswerMutation,
  useEditQuestionAnswerMutation,
  useDeleteQuestionAnswerMutation,
} = questionAnswerApi;
