/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Children, Dispatch, SetStateAction } from 'react';
import './style.scss';

interface IProps {
  open: boolean,
  toggle?: Dispatch<SetStateAction<boolean>>,
  style?: React.CSSProperties,
  id?: string,
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
}

const Modal: React.FC<IProps> = ({ children, open, toggle, style, id }) => {
  const childrenWithProps: any = Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement<any>(child, { toggle });
    }
    return child;
  });

  return (
    <>
      {
        open && (<div className='modal' style={style} id={id}>
          <div className='modal__content'>
            {childrenWithProps}
            <span className='vertical-spacer' />
          </div>
        </div>)
      }
    </>
  );
};

export default Modal;
