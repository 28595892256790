import { SingleValue } from 'react-select';
import { LlmModel } from '../../submodules/common/enums/common/llm-model.enum';
import { IPromptTemplateInfoDTO } from '../../submodules/common/interfaces/dto/prompt-templates/iprompt-template-info.interface';
import { IUpdatePromptTemplateDTO } from '../../submodules/common/interfaces/dto/prompt-templates/iupdate-prompt-template.interface';
import { IPromptTemplateDTO } from '../../submodules/common/interfaces/dto/prompt-templates/iprompt-template.interface';
import { ITypedSelect } from '../../types/selectTypes';

export type TSettings = Partial<
  Record<
    keyof IPromptTemplateInfoDTO,
    string | SingleValue<ITypedSelect<LlmModel>>
  >
> & {
  prompt: string,
  llmModel: SingleValue<ITypedSelect<LlmModel>>,
  llmTemperature: string,
  tokens: string,
  documentRetreiverLimit: string,
};

export const options: ITypedSelect<LlmModel>[] = [
  { label: LlmModel.GPT_3, value: LlmModel.GPT_3 },
  { label: LlmModel.GPT_4, value: LlmModel.GPT_4 },
];

export const settingsDefaultValues: TSettings = {
  prompt: '',
  llmModel: null,
  llmTemperature: '0',
  tokens: '0',
  documentRetreiverLimit: '0',
};

export const preparePromptData = (
  formData: TSettings,
  incomingData: Pick<IPromptTemplateInfoDTO, 'llmModel' | 'llmTemperature'| 'tokens' | 'documentRetreiverLimit'>
): TSettings => {
  const { llmModel, llmTemperature, tokens, documentRetreiverLimit } = incomingData;
  const preparedData: TSettings = {
    ...formData,
    llmTemperature: String(llmTemperature),
    llmModel: { label: llmModel, value: llmModel },
    tokens: String(tokens ?? '0'),
    documentRetreiverLimit: String(documentRetreiverLimit ?? '0'),
  };

  return preparedData;
};

export const prepareDataForUpdateMethod = (
  initialData: IPromptTemplateDTO,
  formData: TSettings
): Omit<IUpdatePromptTemplateDTO, 'created' | 'updated'> => {
  const {
    name,
    // documentSections,
    id,
  } = initialData;
  const { prompt, llmModel, llmTemperature, tokens, documentRetreiverLimit } = formData;

  return {
    name,
    prompt,
    llmModel: (llmModel as SingleValue<ITypedSelect<LlmModel>>)
      ?.value as string,
    llmTemperature: +(llmTemperature as string),
    id: id as string,
    // documentSections,
    questionCategoryId: null,
    // formType: null,
    tokens: Number(tokens),
    documentRetreiverLimit: Number(documentRetreiverLimit),
  };
};

export enum ESuccessMessagesGreetingMessage {
  UPDATE = 'Greeting message has been updated successfully',
}
