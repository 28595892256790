import { EMessageType, IMessageData, IMessageType } from '../types/messagesTypes';


export const prepareErrorMessage = (status: number | string | undefined, errorCode: string | undefined):
  IMessageType => {
  if (status?.toString() === 'FETCH_ERROR') {
    return {
      messageType: EMessageType.ERROR,
      messages: ['Failed to fetch', 'The application failed to retrieve data from the server. Please check your internet connection and  try the operation again.'],
      defaultErrorText: '',
    };
  }
  return {
    messageType: EMessageType.ERROR,
    messages: [status?.toString() ?? '', errorCode ?? ''],
  };
};

export const defaultMessageData = {
  messageData: {
    messageType: '',
    messages: [],
  },
};

export const handleResponse = (response: IMessageData, callback) => {
  if (response?.messageData?.messageType === EMessageType.SUCCESS) {
    callback();
  }
};

export const isResponseIsError = (results: boolean[]) => {
  return results?.some((r) => !!r);
};

export const errorQueryFn: { error: boolean } = { error: true };
