/* eslint-disable max-len */
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IGreetingSettings } from '../../../submodules/common/interfaces/dto/settings/igreeting-settings.interface';
import { SettingsRoutes } from '../../../submodules/common/enums/routes/admin-settings-routes.enum';
import { IListExportSettings } from '../../../submodules/common/interfaces/dto/settings/ilist-export-settings.interface';
import { IGetExportSettings } from '../../../submodules/common/interfaces/dto/settings/iget-export-settings.interface';

import { EMethod } from '../utils';
import { adminApi } from './adminApi';
import { tagTypesSettings } from './utils';
import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { ESuccessMessagesGreetingMessage } from '../../../pages/Settings/utils';
import { ESuccessMessagesSettings } from '../../../pages/ExportImportSettings/ImportTab/utils';

export const settingsApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getGreetingMessage: builder.query<{ data: IGreetingSettings, initialFetching: boolean }, { query: string | null }>({
      query: () => ({
        url: `${SettingsRoutes.BasePrefix}/${SettingsRoutes.GreetingSettings}`,
        method: EMethod.GET,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IGreetingSettings> & { initialFetching: boolean }) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesSettings,
    }),

    updateGreetingMessage: builder.mutation<{ data: IGreetingSettings, initialFetching: boolean } & IMessageData, { body: IGreetingSettings }>({
      query: ({ body }) => ({
        url: `${SettingsRoutes.BasePrefix}/${SettingsRoutes.GreetingSettings}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IGreetingSettings> & { initialFetching: boolean } & IMessageData) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
          messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessagesGreetingMessage.UPDATE] },
        };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesSettings;
      },
    }),

    getSettings: builder.query<
      { data: IListExportSettings; initialFetching: boolean },
      { query: string | null }
    >({
      query: () => ({
        url: `${SettingsRoutes.BasePrefix}/${SettingsRoutes.GetListSettings}`,
        method: EMethod.GET,
      }),
      transformResponse: (
        response: IApiResponseGenericDTO<IListExportSettings> & {
          initialFetching: boolean;
        }
      ) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesSettings,
    }),

    updateSettings: builder.mutation<IMessageData, { body: IGetExportSettings }>({
      query: ({ body }) => ({
        url: `${SettingsRoutes.BasePrefix}/${SettingsRoutes.ExportSettings}`,
        method: EMethod.POST,
        body,
      }),
    }),

    // ---

    getSettingsDiff: builder.mutation<IApiResponseGenericDTO<IListExportSettings[]>, FormData>({
      query: (body) => ({
        url: `${SettingsRoutes.BasePrefix}/${SettingsRoutes.GetSettingsDiff}`,
        method: EMethod.POST,
        body,
        headers: {},
      }),
    }),

    applySettingsImport: builder.mutation<IMessageData, { data: IListExportSettings[] }>({
      query: (body) => ({
        url: `${SettingsRoutes.BasePrefix}/${SettingsRoutes.ApplySettings}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessagesSettings.UPDATE] } };
      },
    }),
  }),
});

export const {
  useGetGreetingMessageQuery,
  useUpdateGreetingMessageMutation,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetSettingsDiffMutation,
  useApplySettingsImportMutation,
  endpoints,
  internalActions: { resetApiState: resetSettingsApi },
} = settingsApi;
