/* eslint-disable @typescript-eslint/no-explicit-any */
import { ELocalStorage } from '../../../../types/localStorageTypes';
import { getTokenByType } from '../../../../utils/localStorageUtil';

export enum ETagType {
  CATEGORIES = 'CATEGORIES',
  QUESTION_ANSWER = 'QUESTION_ANSWER',
  PDF_DOCUMENTS = 'PDF_DOCUMENTS',
  QUESTION_CATEGORIES = 'QUESTION_CATEGORIES',
  PROMPTS = 'PROMPTS',
  SETTINGS = 'SETTINGS',
}

export const tagTypes: string[] = [
  ETagType.CATEGORIES,
  ETagType.QUESTION_ANSWER,
  ETagType.PDF_DOCUMENTS,
  ETagType.PROMPTS,
  ETagType.QUESTION_CATEGORIES,
  ETagType.SETTINGS,
];

export const tagTypesCategories: string[] = [ETagType.CATEGORIES];
export const tagTypesQuestionAnswer: string[] = [ETagType.QUESTION_ANSWER];
export const tagTypesPdfDocuments: string[] = [ETagType.PDF_DOCUMENTS];
export const tagTypesPrompts: string[] = [ETagType.PROMPTS];
export const tagTypesQuestionCategories: string[] = [ETagType.QUESTION_CATEGORIES];
export const tagTypesSettings: string[] = [ETagType.SETTINGS];

export const getBody = () => ({ token: getTokenByType(ELocalStorage.AdminRefreshToken) ?? null }) as any;

export const getPrepareHeaders = (headers: Headers) => {
  return headers.set('Authorization', `Bearer ${getTokenByType(ELocalStorage.AdminAccessToken)}`);
};
