/* eslint-disable @typescript-eslint/no-explicit-any */
import { resetChatStore } from '../redux/reducers/chatAdminSlice';
import { broadcastChannel } from '..';
import { store } from '../redux/store';
import { EEventName } from './events';

interface IPayload {
  type: string;
}

export const broadcastChannelServices = () => {
  broadcastChannel.addEventListener('message', (e) => {
    const { data: { type } } = e;
    if (type === EEventName.RESET_MESSAGE_CHAT_HISTORY) {
      store.dispatch(resetChatStore());
    }
  });
};

export const broadcastChannelServicePost = (payload: IPayload) => {
  broadcastChannel.postMessage(payload);
};
