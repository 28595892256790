export enum ELocalStorage {
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
  AdminAccessToken = 'admin_access_token',
  AdminRefreshToken = 'admin_refresh_token'
}

export enum EToken {
  TOKENS = 'tokens',
}
