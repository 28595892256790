/* eslint-disable max-len */
import { AdminChatRoutes } from '../../../submodules/common/enums/routes/admin-chat-routes.enum';
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IPaginatedResultDTO } from '../../../submodules/common/interfaces/dto/common/ipaginated-result.interface';
import { IGetSessionsTokenDTO } from '../../../submodules/common/interfaces/dto/session/iget-session-token.interface';
import { IGetSessionsRequestDTO } from '../../../submodules/common/interfaces/dto/session/iget-sessions-request.interface';
import { ISessionDTO } from '../../../submodules/common/interfaces/dto/session/isession.interface';
import { EMethod } from '../utils';
import { adminApi } from './adminApi';

export const sessionChatApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getSessionChats: builder.query<{ data: ISessionDTO[], totalCount: number, initialFetching: boolean }, { body: IGetSessionsRequestDTO | null }>({
      query: ({ body }) => ({
        url: AdminChatRoutes.BasePrefix,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPaginatedResultDTO<ISessionDTO>> & { initialFetching: boolean }) => ({
        data: response.data.items,
        totalCount: response.data.totalCount,
        initialFetching: response.initialFetching,
      }),
    }),

    getSessionById: builder.query<{ data: ISessionDTO, initialFetching: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `${AdminChatRoutes.BasePrefix}/sessions/${id}`,
        method: EMethod.GET,
      }),
      transformResponse: (response: IApiResponseGenericDTO<ISessionDTO> & { initialFetching: boolean }) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
        };
      },
    }),

    getChatBySessionId: builder.query<{ data: ISessionDTO[], totalCount: number, initialFetching: boolean }, { id: string, body: IGetSessionsRequestDTO | null }>({
      query: ({ id, body }) => ({
        url: `${AdminChatRoutes.BasePrefix}/messages/${id}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPaginatedResultDTO<ISessionDTO>> & { initialFetching: boolean }) => ({
        data: response.data.items,
        totalCount: response.data.totalCount,
        initialFetching: response.initialFetching,
      }),
    }),
    getSessionTokenForExport: builder.mutation<IApiResponseGenericDTO<string>, IGetSessionsTokenDTO>({
      query: (body) => ({
        url: `${AdminChatRoutes.BasePrefix}/${AdminChatRoutes.GetSessionToken}`,
        method: EMethod.POST,
        body,
      }),
    }),
    exportSessionData: builder.mutation<{ data: string }, { body?: undefined, token: string }>({
      query: ({ body, token }) => ({
        url: `${AdminChatRoutes.BasePrefix}/${AdminChatRoutes.GetSessionsCSV.replace('/:token', `/${token}`)}`,
        method: EMethod.GET,
        body,
      }),
    }),
  }),
});

export const {
  useGetSessionChatsQuery,
  useGetChatBySessionIdQuery,
  useGetSessionTokenForExportMutation,
  useExportSessionDataMutation,
  useGetSessionByIdQuery,
  internalActions: { resetApiState: resetSessionChatApi },
} = sessionChatApi;
