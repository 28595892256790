import FocusTrap from 'focus-trap-react';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import Button from '../Button';
import Modal from '../Modal';
import './style.scss';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  okText?: string;
  cancelText?: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions{
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ConfirmationDialog = ({
  open,
  title = 'Are you sure you want to close?',
  message = 'You will lose all the data entered.',
  okText = 'Close anyway',
  cancelText= 'Cancel',
  onSubmit,
  onClose,
}: ConfirmationDialogProps) => {
  return (
    <Modal open={open}>
      <FocusTrap>
        <div className='closePanel-env'>
          <div className='closePanel-env__header'>
            <p className='closePanel-env__title'>{title}</p>
            <Button className='close' onClick={onClose}><Close /></Button>
          </div>
          <div className='closePanel-env__sections'>
            {typeof message === 'string' ? <p>{message}</p> : message}
          </div>
          <div className='closePanel-env__action-btn'>
            <div><Button className='btn primary' onClick={onSubmit}>{okText}</Button></div>
            <div><Button className='btn' onClick={onClose}>{cancelText}</Button></div>
          </div>
        </div>
      </FocusTrap>
    </Modal>
  );
};

export default ConfirmationDialog;
