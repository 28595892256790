import { isValueIncludes } from '../../../utils/arrayUtil';

export const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export enum EMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum EStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  SUCCESSES = 200,
}

export const isAccessForbidden = (statusCode: number) => {
  return isValueIncludes<number>(statusCode, [EStatusCode.UNAUTHORIZED, EStatusCode.FORBIDDEN]);
};
