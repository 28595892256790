// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (data: any): boolean => {
  return typeof data === 'object' && data !== null;
};

export const isStringMatching = (mainString: string, searchString: string): boolean => {
  return mainString?.toLowerCase().trim().indexOf(searchString?.toLowerCase().trim()) !== -1;
};

export const formatBytes = (bytes: number, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(k)), sizes.length - 1);
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
