import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './assets/scss/index.scss';

import './i18n/config';
import Layout from './layout';
import { PrivateRoute } from './components/PrivateRoute';
import { useAuthSetting } from './hooks/useAuthSetting';
import { broadcastChannel } from '.';
import { broadcastChannelServices } from './events/broadcastChannel';

const Login = React.lazy(() => import('./pages/Login'));
const SessionsChats = React.lazy(() => import('./pages/SessionsChats'));
const SessionChat = React.lazy(() => import('./pages/SessionChat'));
const ChatAdmin = React.lazy(() => import('./pages/ChatAdmin'));
const VersionPage = React.lazy(() => import('./pages/Version'));
const PDFDocuments = React.lazy(() => import('./pages/PDFDocuments'));
const QuestionAnswer = React.lazy(() => import('./pages/QuestionAnswer'));
const DocMdContent = React.lazy(() => import('./pages/DocMdContent'));
const Settings = React.lazy(() => import('./pages/Settings'));
const ExportImportSettings = React.lazy(() => import('./pages/ExportImportSettings'));

// const QuestionCategories = React.lazy(() => import('./pages/QuestionCategories'));
// const Category = React.lazy(() => import('./pages/Category'));

function App() {
  const { initialAuthSetting } = useAuthSetting();
  const to = '/login';

  useEffect(() => {
    broadcastChannelServices();

    return () => {
      broadcastChannel.close();
    };
  }, []);

  return (
    <>
      {initialAuthSetting &&
        <div className='content' >
          <Routes>
            <Route
              element={
                <React.Suspense fallback={null}>
                  <Login />
                </React.Suspense>
              }
              path='/login'
            />

            <Route
              element={
                <React.Suspense fallback={null}>
                  <VersionPage />
                </React.Suspense>
              }
              path='/version'
            />

            <Route path='/qa' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><QuestionAnswer /></React.Suspense>
                }
              />
            </Route>

            <Route path='/sessions-chat' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><SessionsChats /></React.Suspense>
                }
              />
            </Route>

            <Route path='/sessions-chat/:id' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><SessionChat /></React.Suspense>
                }
              />
            </Route>

            <Route path='/chat-admin' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <ChatAdmin />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='/documents' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <PDFDocuments />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='/documents/:id' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><DocMdContent /></React.Suspense>
                }
              />
            </Route>

            <Route path='/settings' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <Settings />
                  </React.Suspense>
                }
              />
            </Route>

            {/* <Route path='/question-categories' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><QuestionCategories /></React.Suspense>
                }
              />
            </Route> */}

            {/* <Route path='/question-categories/:id' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><Category /></React.Suspense>
                }
              />
            </Route> */}

            <Route path='/export-import-settings' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <ExportImportSettings />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='*' element={<Navigate replace to={to} />} />
          </ Routes>
        </div >
      }
    </>
  );
}

export default App;
