import { useEffect } from 'react';
import { authService, authServiceSetting } from '../services/authService';
import { EEventName, removeEvent, subscribeEvent } from '../events/events';
import { useAuth } from './useTypeSelectorHooks/useAuth';

export const useAuthSetting = (): { initialAuthSetting: boolean } => {
  const { initialFetching: initialAuthSetting } = useAuth();

  useEffect(() => {
    authServiceSetting();
    subscribeEvent(EEventName.STORAGE, authService);
    return () => {
      removeEvent(EEventName.STORAGE, authService);
    };
  }, []);

  return { initialAuthSetting };
};
